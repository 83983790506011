<template>
    <div
        v-if="message && !(message.type === 'client_note' && !isClient())"
        class="message"
        :data-message-alignment="alignment"
        :data-loading="loading"
        :class="{
            'message--highlight': isHighlight,
            [`message--${message.type}`]: true,
            'message--deleted': !!message?.deleted_at,
            'message--draft': isProposal && proposal.status === 'draft',
        }"
    >
        <aside class="message__aside">
            <nav class="message__actions">
                <slot
                    name="actions"
                    :message="message"
                >
                    <multiselect
                        v-if="allowedToRemove"
                        class="multiselect--dotted multiselect--light multiselect--icon-only"
                        :options="actions"
                        :searchable="false"
                        :reset-after="true"
                        :select-label="''"
                        :placeholder="''"
                        :custom-label="formatActionLabel"
                        @select="dispatchAction"
                    />
                </slot>
            </nav>

            <div
                v-if="message.type !== 'notice' && message[authorProperty] && avatarPosition === 'on-top'"
                class="message__profile-picture"
            >
                <avatar
                    class="avatar--small"
                    size="small"
                    :user="message[authorProperty]"
                />
            </div>
        </aside>

        <article class="message__content">
            <template v-if="!!message.deleted_at">
                <em>{{ $t('message.is_deleted') }}</em>
            </template>

            <template v-else>
                <h2 v-if="title && !isProposal">{{ title }}</h2>

                <p v-if="content && !isProposal && !isAssignment">{{ content }}</p>

                <template v-else-if="isAssignment && assignment.content">
                    <p
                        class="message__text"
                        :class="{
                            'message__text--overflow': shouldOverflow(flattenContent(assignment.content))
                        }"
                    >
                        <template v-if="shouldOverflow(flattenContent(assignment.content))">{{ truncate(flattenContent(assignment.content), 200) }}</template>
                        <template v-else>{{ flattenContent(assignment.content) }}</template>
                    </p>
                    <a
                        v-if="shouldOverflow(flattenContent(assignment.content))"
                        class="message__link"
                        @click="readMore"
                    >
                        {{ $t('readMore.label.more') }}
                    </a>
                </template>

                <slot v-if="message.note">
                    <p>{{ message.note }}</p>
                </slot>

                <slot v-if="message.comment">
                    <p>{{ message.comment }}</p>
                </slot>

                <template v-if="isProposal && proposal">
                    <li
                        v-if="proposal.draft_id && (isLawyer() || isCommunityManager())"
                        class="proposal-meta__status"
                    >
                        <span class="tag">
                            {{ $t('proposalView.status-source-draft') }}
                        </span>
                    </li>

                    <proposal-meta :proposal="proposal" />

                    <div class="message__section">
                        <router-link
                            class="btn btn--small btn--medium"
                            :to="{
                                name: 'proposal.detail',
                                params: {
                                    id: proposal.id
                                }
                            }"
                        >
                            {{ $t('message.view-proposal') }}
                        </router-link>
                        <button
                            v-if="proposal.status === 'draft'"
                            class="btn btn--small btn--medium"
                            style="margin-left: 5px;"
                            @click.prevent="confirmAndSendProposal"
                        >
                            {{ $t('message.send-proposal.btn') }}
                        </button>
                    </div>

                    <div
                        v-if="!!proposal.accepted_at"
                        class="message__section"
                    >
                        <confirmed-message>
                            {{ $t('proposalView.accepted-message', {
                                date: $options.filters.dateFormat(
                                    proposal.accepted_at,
                                    $i18n.t('date-format.date')
                                )
                            }) }}
                        </confirmed-message>
                    </div>

                    <div
                        v-if="!!proposal.closed_at"
                        class="message__section"
                    >
                        <closed-message>
                            {{ $t('proposalView.closed-message', {
                                date: $options.filters.dateFormat(
                                    proposal.closed_at,
                                    $i18n.t('date-format.date')
                                ),
                                action: proposal.closed_by === proposal.user.id ? $t('withdrawn').toLowerCase() : $t('rejected').toLowerCase()
                            }) }}
                        </closed-message>
                    </div>
                </template>

                <template v-if="isAssignment">
                    <assignment-meta :assignment="assignment" />
                </template>

                <template v-if="hasAttachments">
                    <ul class="list--clean">
                        <li
                            v-for="attachment in message.media"
                            :key="attachment.id"
                        >
                            <file-download
                                :attachment="attachment"
                                :href="getAttachmentUrl(attachment.id)"
                            />
                        </li>
                    </ul>
                </template>
            </template>
        </article>

        <footer
            v-if="message.type !== 'notice'"
            class="message__footer"
        >
            <div
                v-if="message[authorProperty] && avatarPosition === 'below'"
                class="message__profile-picture"
            >
                <avatar
                    class="avatar--small"
                    size="small"
                    :user="message[authorProperty]"
                />
            </div>
            <div class="message__meta">
                <slot
                    name="meta"
                    :message="message"
                >
                    <span
                        v-if="!isMe && message.user"
                        class="message__author"
                    >
                        {{ message.user.full_name }} <colleague-icon v-if="hasClientRole && !isMe" />
                    </span>

                    <time class="message__time">
                        <template v-if="!isMe">
                            -
                        </template>

                        <template v-if="message.created_at">
                            {{ message.created_at | humanDateTime }}
                        </template>

                        <template v-if="isAssignment && assignment.isEdited && assignment.updated_at">
                            - {{ $t('message.updated', {
                                datetime: $options.filters.humanDateTime(
                                    assignment.updated_at
                                )
                            }) }}
                        </template>
                    </time>
                </slot>
            </div>
        </footer>
    </div>
</template>

<script>
import { isEmpty } from 'lodash-es';
import { differenceInHours, parseISO } from 'date-fns';
import Multiselect from 'vue-multiselect';
import { isMe, isClient, getLoggedInUser, isAdmin, isCommunityManager } from '~/helpers/user';
import { formatActionLabel } from '~/helpers/assignment';

import DeleteMessageMutation from '~/graphql/mutations/deleteMessage.graphql';
import SendDraftProposalMutation from '~/graphql/mutations/sendDraftToClient.graphql';
import {EVENT_PROPOSAL_CREATED} from '~/constants/events';
import {REMOVE_MESSAGE} from '~/constants/message-actions';
import Avatar from '~/patterns/atoms/avatar/avatar.vue';
import ColleagueIcon from '~/patterns/atoms/colleague-icon/colleague-icon.vue';
import ConfirmedMessage from '~/patterns/atoms/confirmed-message/confirmed-message.vue';
import ClosedMessage from '~/patterns/atoms/closed-message/closed-message.vue';
import FileDownload from '~/patterns/atoms/file-download/file-download.vue';

import ProposalMeta from '~/patterns/molecules/proposal-meta/proposal-meta.vue';
import AssignmentMeta from '~/patterns/molecules/assignment-meta/assignment-meta.vue';

import truncate from '~/filters/truncate';

export default {
    components: {
        Avatar,
        ColleagueIcon,
        ConfirmedMessage,
        ClosedMessage,
        FileDownload,
        ProposalMeta,
        AssignmentMeta,
        Multiselect
    },

    props: {
        message: {
            type: Object,
            required: true
        },
        authorProperty: {
            type: String,
            default: 'user'
        },
        avatarPosition: {
            type: String,
            default: 'on-top'
        }
    },

    emits: ['readMore'],

    data() {
        return {
            loading: false,
            actions: [REMOVE_MESSAGE]
        };
    },

    computed: {
        allowedToRemove() {
            if (this.loading || !this.message || this.message.type !== 'reply' || !!this.message.deleted_at) {
                return false;
            }

            // Dont allow older messages to be removed
            const createdAt = parseISO(this.message.created_at);
            if (differenceInHours(new Date(), createdAt) > 48) {
                return false;
            }

            // Always allow removing own posts
            const loggedInUser = getLoggedInUser();
            if (loggedInUser.id === this.message?.user?.id) {
                return true;
            }

            // Allow when admin/manager
            return isAdmin(loggedInUser) || isCommunityManager(loggedInUser);
        },

        threadId() {
            return this.$route.params.threadId;
        },

        title() {
            if (this.isAssignment) {
                return this.assignment.title;
            }

            if (this.isProposal) {
                return this.proposal.title;
            }

            return this.message.title;
        },

        content() {
            if (!this.message.content) {
                return null;
            }

            const content = this.message.content;

            return content
                // Strip all html tags
                .replace(/(<([^>]+)>)/gi, '');

        },

        loggedInUser() {
            return this.$store.getters['auth/user'];
        },

        alignment() {
            if (this.message.type === 'notice' || !this.message.user?.id || !this.loggedInUser) {
                return false;
            }

            return this.message.user.id === this.loggedInUser.id ? 'right' : 'left';
        },

        isHighlight() {
            return this.isProposal || this.isAssignment;
        },

        isProposal() {
            return this.message.type === 'proposal';
        },

        hasAttachments() {
            return this.message.media && this.message.media.length;
        },

        proposal() {
            if (isEmpty(this.message.proposal)) {
                return false;
            }

            const proposal = { ...this.message.proposal };

            if (proposal.assignment?.proposal_form_config?.open_questions) {
                proposal.open_questions = this.message.proposal.assignment?.proposal_form_config?.open_questions;
            }

            return proposal;
        },

        isAssignment() {
            return this.message.type === 'assignment';
        },

        assignment() {
            if (isEmpty(this.message.assignment)) {
                return false;
            }

            return this.message.assignment;
        },

        hasClientRole() {
            return isClient(this.message.user);
        },

        isMe() {
            return isMe(this.message.user?.id);
        }
    },

    methods: {
        dispatchAction(actionName) {
            switch (actionName) {
            case REMOVE_MESSAGE:
                this.confirmToRemove();
                break;
            default:
                this.changeAssignmentStatus(actionName);
            }
        },

        getAttachmentUrl(mediaId) {
            return `${window.appConfig.api.url}/v1.0/messages/${this.message.id}/${this.threadId}/${mediaId}/download`;
        },

        async confirmToRemove() {
            if (window.confirm(this.$t('message.delete.confirm'))) {
                this.loading = true;
                try {
                    const { data } = await this.$executeGqlQuery(DeleteMessageMutation, { id: this.message.id });
                    this.$emit('messageDeleted', data.message);
                } catch (error) {
                    const errorMessage = error.gqlErrors?.[0]?.message || this.$i18n.t('message.delete.error');
                    this.$store.dispatch('toast/add', {
                        content: errorMessage,
                        severity: 'error'
                    });
                }
                this.loading = false;
            }
        },

        async confirmAndSendProposal() {
            if (window.confirm(this.$t('message.send-proposal.confirm'))) {
                this.loading = true;
                try {
                    // Todo: consider creating a reusable mixin/ api for this or in ~/api/proposals;
                    const { data } = await this.$executeGqlQuery(SendDraftProposalMutation, { id: this.proposal.id });

                    this.$store.dispatch('toast/add', {
                        content: this.$t('message.send-proposal.success'),
                        severity: 'success'
                    });

                    this.$eventBus.$emit(EVENT_PROPOSAL_CREATED);
                    this.$router.push({
                        name: 'proposal.detail',
                        params: { id: data.sendDraftProposal.id },
                    });
                } catch (error) {
                    const errorMessage = error.gqlErrors?.[0]?.message || this.$i18n.t('message.send-proposal.error');
                    this.$store.dispatch('toast/add', {
                        content: errorMessage,
                        severity: 'error'
                    });
                }
                this.loading = false;
            }
        },

        readMore() {
            this.$emit('readMore');
        },

        flattenContent(content) {
            return content
                // Replace all </p> with newlines
                .replace(/<\/p>/gi, '\n')
                // Replace all </li> with newlines
                // .replace(/<\/li>/gi, '\n')
                // Strip all html tags
                .replace(/(<([^>]+)>)/gi, '');
            // Remove all superfluous whitespace
            // .replace(/\s+/g, ' ').trim();

        },

        shouldOverflow(content) {
            if (!content) {
                return;
            }

            return content.length > 200;
        },

        formatActionLabel,
        truncate
    }
};
</script>

<style lang="less" src="./message.less" />
