
<dn-modal
    v-if="!loading && assignment"
    name="create-proposal"
    size="large"
    position="centered"
    :close-prompt="$t('modal.create-proposal.close-prompt')"
>
    <modal-content class="modal-content--form">
        <h1 class="text-center">
            {{ $t('modal.create-proposal.modal-title') }}

            <span
                v-if="isDraft"
                class="text--muted"
            >
                {{ $t('modal.create-proposal.draft') }}
            </span>
        </h1>

        <a
            class="create-proposal__assignment-link"
            :href="`/assignment/${assignment.id}/thread/${threadId}/detail`"
            target="_blank"
        >
            {{ $t('actions.view-assignment') }}: {{ assignment.title }}
            <svg-icon icon="external-link" />
        </a>

        <form
            class="form"
            data-vv-scope="proposal-create"
            @submit.prevent="submitProposal"
        >
            <llq-notice
                v-if="isPrefillData"
                severity="warning"
                style="margin-bottom: 1rem;"
            >
                <div>{{ $t('modal.create-proposal.prefill-notice') }}</div>
                <div>{{ $t('modal.create-proposal.prefill-notice-attachment-supplement') }}</div>
            </llq-notice>

            <form-field
                v-if="!isHidden('APPROACH')"
                :error-messages="errors.collect('proposal-create.content')"
                :required="isRequired('APPROACH')"
            >
                <label
                    slot="label"
                    for="createProposalContent"
                >
                    {{ $t('proposalView.content') }}
                </label>

                <span
                    v-if="hasDescription('APPROACH')"
                    slot="description"
                >
                    {{ getDescription('APPROACH') }}
                </span>

                <MarkupTextarea
                    id="createProposalContent"
                    v-model="formData.content"
                    v-validate="{ required: isRequired('APPROACH') }"
                    name="content"
                    rows="6"
                    :placeholder="$t('modal.create-proposal.content.placeholder')"
                />
            </form-field>

            <form-field
                v-if="!isHidden('START_DATE')"
                :error-messages="errors.collect('proposal-create.starts_at')"
                :required="isRequired('START_DATE')"
                columns-xs-min="12/12"
                columns-md-min="6/12"
            >
                <label
                    slot="label"
                    for="createProposalStartDate"
                >
                    {{ $t('proposalView.starts_at') }}
                </label>

                <span
                    v-if="hasDescription('START_DATE')"
                    slot="description"
                >
                    {{ getDescription('START_DATE') }}
                </span>

                <datepicker
                    id="createProposalStartDate"
                    v-model="formData.starts_at"
                    v-validate="{ required: isRequired('START_DATE') }"
                    name="starts_at"
                    :placeholder="$t('modal.create-proposal.starts_at.placeholder')"
                />
            </form-field>

            <form-section
                v-if="!isHidden('TARIFF_ANY')"
            >
                <template #header>
                    {{ $t('modal.create-proposal.tariff.title') }}
                    <span
                        v-if="!isRequired('TARIFF_ANY')"
                        class="text--muted"
                    >
                        ({{ $t('general.optional') }})
                    </span>
                </template>

                <template #description>
                    <span v-if="hasDescription('TARIFF_ANY')">{{ getDescription('TARIFF_ANY') }}<br></span>
                </template>

                <div
                    v-if="addedPercentagePoints > 0 && !userIsClientSourcedSupplier"
                    class="form__message"
                >
                    {{ $t('modal.create-proposal.tariff.fee_message', { fee: 100 - addedPercentagePoints}) }}
                </div>

                <repeater
                    :items="formData.tariffs"
                    columns
                >
                    <template #items="{ index }">
                        <form-field
                            columns-xs-min="12/12"
                            columns-md-min="7/12"
                            :error-messages="errors.collect('proposal-create.tariffs')"
                            :required="isRequired('TARIFF_ANY') || formData.tariffs[index].amount !== ''"
                        >
                            <label slot="label">{{ $t('modal.create-proposal.tariff.label.type') }}</label>
                            <input
                                v-model="formData.tariffs[index].type"
                                v-validate="{ required: isRequired('TARIFF_ANY') || formData.tariffs[index].amount !== '' }"
                                type="text"
                                name="tariff_type"
                                :placeholder="$t('modal.create-proposal.tariff.placeholder.type')"
                            >
                        </form-field>

                        <form-field
                            columns-xs-min="12/12"
                            columns-md-min="3/12"
                            :error-messages="errors.collect('proposal-create.tariffs')"
                            :required="isRequired('TARIFF_ANY') || formData.tariffs[index].type !== ''"
                            icon-position="left"
                        >
                            <template v-slot:icon>€</template>

                            <label slot="label">{{ $t('modal.create-proposal.tariff.label.value') }}</label>

                            <input
                                v-model="formData.tariffs[index].amount"
                                v-validate="`${(isRequired('TARIFF_ANY') || formData.tariffs[index].type) ? 'required|' : ''}decimal:2`"
                                type="number"
                                max="999999"
                                :lang="$i18n.locale"
                                name="tariff"
                                :placeholder="$n('0.00', { minimumFractionDigits: 2 })"
                            >
                        </form-field>
                    </template>

                    <template #info="{ index }">
                        <tariff-calculation :tariff="formData.tariffs[index].amount" />
                    </template>
                </repeater>

                <note-field v-model="formData.tariff_note" />
            </form-section>

            <form-section
                v-if="!isHidden('WORKLOAD_ANY')"
            >
                <template #header>
                    {{ $t('modal.create-proposal.workload.title') }}
                    <span
                        v-if="!isRequired('WORKLOAD_ANY')"
                        class="text--muted"
                    >
                        ({{ $t('general.optional') }})
                    </span>
                </template>

                <template
                    #description
                >
                    <span v-if="hasDescription('WORKLOAD_ANY')">{{ getDescription('WORKLOAD_ANY') }}</span>
                    <span
                        v-else
                    >
                        {{ $t('modal.create-proposal.workload.description') }}
                    </span>
                </template>

                <div
                    v-if="addedPercentagePoints > 0 && !userIsClientSourcedSupplier"
                    class="form__message"
                >
                    {{ $t('modal.create-proposal.workload.fee_message', { fee: 100 - addedPercentagePoints}) }}
                </div>

                <div class="form-field">
                    <radio
                        v-model="formData.workload_type"
                        class="radio--inline"
                        name="type-radio"
                        value="total"
                    >
                        {{ $t('modal.create-proposal.workload.label.total') }}
                    </radio>

                    <radio
                        v-model="formData.workload_type"
                        class="radio--inline"
                        name="type-radio"
                        value="range"
                    >
                        {{ $t('modal.create-proposal.workload.label.range') }}
                    </radio>
                </div>

                <repeater
                    :items="formData.workloads"
                    columns
                    @input="workloadsChange"
                >
                    <template #items="{ index }">
                        <form-field
                            columns-xs-min="12/12"
                            :columns-md-min="formData.workload_type === 'range' ? '12/12' : '7/12'"
                            :columns-lg-min="formData.workload_type === 'range' ? '4/12' : '7/12'"
                            :error-messages="errors.collect('proposal-create.tariffs')"
                            :required="isRequired('WORKLOAD_ANY') || formData.workloads[index].total_estimate !== '' || formData.workloads[index].lower_boundary !== '' || formData.workloads[index].upper_boundary !== ''"
                        >
                            <label slot="label">{{ $t('modal.create-proposal.workload.label.description') }}</label>
                            <input
                                v-model="formData.workloads[index].description"
                                v-validate="{ required: isRequired('WORKLOAD_ANY') || formData.workloads[index].total_estimate !== '' }"
                                type="text"
                                name="workload"
                                :readonly="formData.workloads[index].isPrefilled === true"
                                :placeholder="$t('modal.create-proposal.workload.placeholder.description')"
                            >
                        </form-field>

                        <template v-if="formData.workload_type === 'range'">
                            <form-field
                                columns-xs-min="12/12"
                                columns-md-min="4/12"
                                columns-lg-min="3/12"
                                :required="isRequired('WORKLOAD_ANY') && formData.workload_type === 'range'"
                                :error-messages="errors.collect('proposal-create.tariffs')"
                                icon-position="left"
                            >
                                <template v-slot:icon>€</template>

                                <label slot="label">{{ $t('modal.create-proposal.workload.label.lower_boundary') }}</label>

                                <input
                                    v-model="formData.workloads[index].lower_boundary"
                                    v-validate="`${(isRequired('WORKLOAD_ANY') && formData.workload_type === 'range') ? 'required|' : ''}decimal:2`"
                                    type="number"
                                    name="workload_lower"
                                    max="999999"
                                    min="0"
                                    :lang="$i18n.locale"
                                    :placeholder="$n('0.00', { minimumFractionDigits: 2 })"
                                >
                            </form-field>

                            <form-field
                                columns-xs-min="12/12"
                                columns-md-min="4/12"
                                columns-lg-min="3/12"
                                :error-messages="errors.collect('proposal-create.tariffs')"
                                :required="isRequired('WORKLOAD_ANY') && formData.workload_type === 'range'"
                                icon-position="left"
                            >
                                <template v-slot:icon>€</template>

                                <label slot="label">{{ $t('modal.create-proposal.workload.label.upper_boundary') }}</label>

                                <input
                                    v-model="formData.workloads[index].upper_boundary"
                                    v-validate="`${(isRequired('WORKLOAD_ANY') && formData.workload_type === 'range') ? 'required|' : ''}decimal:2`"
                                    type="number"
                                    name="workload_upper"
                                    max="999999"
                                    min="0"
                                    :lang="$i18n.locale"
                                    :placeholder="$n('0.00', { minimumFractionDigits: 2 })"
                                >
                            </form-field>
                        </template>
                        <template v-else>
                            <form-field
                                columns-xs-min="12/12"
                                columns-md-min="3/12"
                                columns-lg-min="3/12"
                                :error-messages="errors.collect('proposal-create.tariffs')"
                                :required="isRequired('WORKLOAD_ANY') && formData.workload_type === 'total'"
                                icon-position="left"
                            >
                                <template v-slot:icon>€</template>

                                <label slot="label">{{ $t('modal.create-proposal.workload.label.total_estimate') }}</label>

                                <input
                                    v-model="formData.workloads[index].total_estimate"
                                    v-validate="`${(isRequired('WORKLOAD_ANY') && formData.workload_type === 'total') ? 'required|' : ''}decimal:2`"
                                    name="workload_total"
                                    type="number"
                                    max="999999"
                                    min="0"
                                    :lang="$i18n.locale"
                                    :placeholder="$n('0.00', { minimumFractionDigits: 2 })"
                                >
                            </form-field>
                        </template>
                    </template>

                    <template #info="{ index }">
                        <note-field
                            :key="`workloadNote_${index}`"
                            v-model="formData.workloads[index].remarks"
                            class="note-field--inline"
                            :force-active.sync="formData.workloads[index].hasRemarks"
                            :with-toggle="false"
                        />
                    </template>

                    <template #buttons="{ index }">
                        <v-button
                            v-if="formData.workloads[index].hasRemarks !== true"
                            class="repeater__button"
                            @click.prevent="formData.workloads[index].hasRemarks = true"
                        >
                            <svg-icon
                                icon="note-plus"
                            />
                        </v-button>

                        <v-button
                            v-else
                            class="repeater__button"
                            @click.prevent="formData.workloads[index].hasRemarks = false"
                        >
                            <svg-icon icon="chat-error" />
                        </v-button>
                    </template>
                </repeater>

                <tariff-calculation-total
                    :type="formData.workload_type"
                    :workloads="formData.workloads"
                />

                <note-field v-model="formData.workload_note" />
            </form-section>

            <form-section
                v-if="!isHidden('FIXED_FEE')"
            >
                <div class="column-grid">
                    <form-field
                        columns-xs-min="12/12"
                        columns-md-min="6/12"
                        :error-messages="errors.collect('proposal-create.fixed_fee')"
                        :required="isRequired('FIXED_FEE')"
                    >
                        <template #description>
                            <span v-if="hasDescription('FIXED_FEE')">{{ getDescription('FIXED_FEE') }}<br></span>
                            <span
                                v-if="addedPercentagePoints > 0 && !userIsClientSourcedSupplier"
                                class="form__message"
                            >
                                {{ $t('modal.create-proposal.tariff.fee_message', { fee: 100 - addedPercentagePoints}) }}
                            </span>
                        </template>

                        <template v-slot:icon>€</template>

                        <label slot="label">{{ $t('modal.create-proposal.fixed_fee.label') }}</label>

                        <input
                            v-model="formData.fixed_fee_amount"
                            v-validate="`${(isRequired('FIXED_FEE')) ? 'required|' : ''}decimal:2`"
                            name="fixed_fee"
                            type="number"
                            min="0"
                            :lang="$i18n.locale"
                            :placeholder="$n('0.00', { minimumFractionDigits: 2 })"
                        >
                    </form-field>
                </div>

                <tariff-calculation
                    class="tariff-calculation--fullwidth"
                    :tariff="formData.fixed_fee_amount"
                />

                <note-field v-model="formData.fixed_fee_note" />
            </form-section>

            <form-section
                v-if="!isHidden('AVAILABILITY_ANY')"
            >
                <template #header>
                    {{ $t('modal.create-proposal.availability.title') }}
                </template>

                <template
                    #description
                >
                    <span v-if="hasDescription('AVAILABILITY_ANY')">{{ getDescription('AVAILABILITY_ANY') }}</span>
                    <span
                        v-else
                    >
                        {{ $t('modal.create-proposal.availability.description') }}
                    </span>
                </template>

                <div class="column-grid">
                    <form-field
                        columns-xs-min="12/12"
                        columns-md-min="2/12"
                        :error-messages="errors.collect('proposal-create.availability')"
                        :required="isRequired('AVAILABILITY_ANY')"
                    >
                        <label slot="label">{{ $t('modal.create-proposal.availability.label.amount') }}</label>
                        <input
                            v-model="formData.availability_amount"
                            v-validate="{ required: isRequired('AVAILABILITY_ANY') }"
                            name="availability"
                            type="number"
                            min="0"
                        >
                    </form-field>

                    <form-field
                        columns-xs-min="12/12"
                        columns-md-min="5/12"
                        :error-messages="errors.collect('proposal-create.availability')"
                        :required="isRequired('AVAILABILITY_ANY')"
                    >
                        <label slot="label">{{ $t('modal.create-proposal.availability.label.unit') }}</label>
                        <multiselect
                            v-model="formData.availability_per_time_unit"
                            v-validate="{ required: isRequired('AVAILABILITY_ANY') }"
                            :options="availabilityDurationOptions"
                            :searchable="false"
                            :show-labels="false"
                            :multiple="false"
                            :allow-empty="isRequired('AVAILABILITY_ANY')"
                            :custom-label="availabilityDurationLabel"
                        />
                    </form-field>
                </div>

                <note-field v-model="formData.availability_note" />
            </form-section>

            <form-section
                v-if="!isHidden('MEDIA')"
            >
                <form-field
                    :required="isRequired('MEDIA')"
                    :error-messages="errors.collect('proposal-create.media')"
                    columns-xs-min="12/12"
                >
                    <template #label>
                        {{ $t('modal.create-proposal.media.title') }}
                    </template>

                    <template
                        v-if="hasDescription('MEDIA')"
                        #description
                    >
                        <span>{{ getDescription('MEDIA') }}</span>
                    </template>
                    <div
                        v-for="(media, key) in formData.media"
                        :key="key"
                    >
                        <file-attach
                            v-if="formData.media[key] !== null"
                            v-model="formData.media[key]"
                            v-validate="{ required: isRequired('MEDIA') && !hasAnyMedia }"
                            name="media"
                        />

                        <!--Extra `file-attach` for 'next attachment'-->
                        <file-attach
                            v-if="key === formData.media.length - 1"
                            v-model="formData.media[key + 1]"
                            v-validate="{ required: isRequired('MEDIA') && !hasAnyMedia }"
                            name="media"
                        />
                    </div>
                </form-field>
            </form-section>

            <form-section
                v-if="!isHidden('RESUME')"
            >
                <form-field
                    columns-xs-min="12/12"
                    :required="isRequired('RESUME')"
                    :error-messages="errors.collect('proposal-create.cv')"
                >
                    <template
                        v-if="hasDescription('RESUME')"
                        #description
                    >
                        <span>{{ getDescription('RESUME') }}</span>
                    </template>
                    <template #label>
                        {{ $t('modal.create-proposal.cv.title') }}
                        <span
                            v-if="!isRequired('RESUME')"
                            class="muted"
                        >
                            ({{ $t('general.optional') }})
                        </span>
                    </template>
                    <file-attach
                        v-model="formData.cv"
                        v-validate="{ required: isRequired('RESUME') }"
                        name="cv"
                    />
                </form-field>
            </form-section>

            <template v-if="openQuestions.length && formData.open_questions_answers.length">
                <form-field
                    v-for="(question, index) in openQuestions"
                    :key="question.id"
                    :required="question.required"
                    :error-messages="errors.collect(`proposal-create.open-questions-${question.id}`)"
                >
                    <label
                        slot="label"
                        for="createProposalDescription"
                    >
                        {{ question.label }} {{ question.required ? '*' : '' }}
                    </label>

                    <MarkupTextarea
                        id="createProposalDescription"
                        v-model="formData.open_questions_answers[index].value"
                        v-validate="{ required: question.required }"
                        :name="`open-questions-${question.id}`"
                        rows="3"
                    />
                </form-field>
            </template>

            <form-field
                :error-messages="errors.collect('proposal-create.remarks')"
            >
                <label
                    slot="label"
                    for="createProposalDescription"
                >
                    {{ $t('proposalView.remarks') }}
                </label>

                <MarkupTextarea
                    id="createProposalDescription"
                    v-model="formData.remarks"
                    name="remarks"
                    rows="3"
                />
            </form-field>

            <form-section
                v-if="errors.items.length > 0"
                class="form-section--errors"
            >
                <template v-if="errors.items.filter((error) => error.field !== null && error.rule === 'required').length > 0">
                    <h5>
                        {{ $t('proposalView.error-message.required') }}
                    </h5>
                    <ul>
                        <li
                            v-for="error in errors.items.filter((error) => error.field !== null && error.rule === 'required')"
                            :key="error.id"
                        >
                            {{ $t(`proposalView.${error.field}`) }}
                        </li>
                    </ul>
                </template>

                <template v-if="errors.items.filter((error) => error.field !== null && error.rule !== 'required').length > 0">
                    <h5>
                        {{ $t('proposalView.error-message.wrong-input') }}
                    </h5>
                    <ul>
                        <li
                            v-for="error in errors.items.filter((error) => error.field !== null && error.rule !== 'required')"
                            :key="error.id"
                        >
                            {{ $t(`proposalView.${error.field}`) }}
                        </li>
                    </ul>
                </template>
            </form-section>

            <form-field class="form-field--last">
                <v-button
                    class="btn--secondary btn--wide"
                    :state="requestState"
                    @click="submitProposal"
                >
                    {{ $t('modal.create-proposal.cta') }}
                </v-button>
            </form-field>
        </form>
    </modal-content>
</dn-modal>
