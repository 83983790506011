
<div
    v-if="message && !(message.type === 'client_note' && !isClient())"
    class="message"
    :data-message-alignment="alignment"
    :data-loading="loading"
    :class="{
        'message--highlight': isHighlight,
        [`message--${message.type}`]: true,
        'message--deleted': !!message?.deleted_at,
        'message--draft': isProposal && proposal.status === 'draft',
    }"
>
    <aside class="message__aside">
        <nav class="message__actions">
            <slot
                name="actions"
                :message="message"
            >
                <multiselect
                    v-if="allowedToRemove"
                    class="multiselect--dotted multiselect--light multiselect--icon-only"
                    :options="actions"
                    :searchable="false"
                    :reset-after="true"
                    :select-label="''"
                    :placeholder="''"
                    :custom-label="formatActionLabel"
                    @select="dispatchAction"
                />
            </slot>
        </nav>

        <div
            v-if="message.type !== 'notice' && message[authorProperty] && avatarPosition === 'on-top'"
            class="message__profile-picture"
        >
            <avatar
                class="avatar--small"
                size="small"
                :user="message[authorProperty]"
            />
        </div>
    </aside>

    <article class="message__content">
        <template v-if="!!message.deleted_at">
            <em>{{ $t('message.is_deleted') }}</em>
        </template>

        <template v-else>
            <h2 v-if="title && !isProposal">{{ title }}</h2>

            <p v-if="content && !isProposal && !isAssignment">{{ content }}</p>

            <template v-else-if="isAssignment && assignment.content">
                <p
                    class="message__text"
                    :class="{
                        'message__text--overflow': shouldOverflow(flattenContent(assignment.content))
                    }"
                >
                    <template v-if="shouldOverflow(flattenContent(assignment.content))">{{ truncate(flattenContent(assignment.content), 200) }}</template>
                    <template v-else>{{ flattenContent(assignment.content) }}</template>
                </p>
                <a
                    v-if="shouldOverflow(flattenContent(assignment.content))"
                    class="message__link"
                    @click="readMore"
                >
                    {{ $t('readMore.label.more') }}
                </a>
            </template>

            <slot v-if="message.note">
                <p>{{ message.note }}</p>
            </slot>

            <slot v-if="message.comment">
                <p>{{ message.comment }}</p>
            </slot>

            <template v-if="isProposal && proposal">
                <li
                    v-if="proposal.draft_id && (isLawyer() || isCommunityManager())"
                    class="proposal-meta__status"
                >
                    <span class="tag">
                        {{ $t('proposalView.status-source-draft') }}
                    </span>
                </li>

                <proposal-meta :proposal="proposal" />

                <div class="message__section">
                    <router-link
                        class="btn btn--small btn--medium"
                        :to="{
                            name: 'proposal.detail',
                            params: {
                                id: proposal.id
                            }
                        }"
                    >
                        {{ $t('message.view-proposal') }}
                    </router-link>
                    <button
                        v-if="proposal.status === 'draft'"
                        class="btn btn--small btn--medium"
                        style="margin-left: 5px;"
                        @click.prevent="confirmAndSendProposal"
                    >
                        {{ $t('message.send-proposal.btn') }}
                    </button>
                </div>

                <div
                    v-if="!!proposal.accepted_at"
                    class="message__section"
                >
                    <confirmed-message>
                        {{ $t('proposalView.accepted-message', {
                            date: $options.filters.dateFormat(
                                proposal.accepted_at,
                                $i18n.t('date-format.date')
                            )
                        }) }}
                    </confirmed-message>
                </div>

                <div
                    v-if="!!proposal.closed_at"
                    class="message__section"
                >
                    <closed-message>
                        {{ $t('proposalView.closed-message', {
                            date: $options.filters.dateFormat(
                                proposal.closed_at,
                                $i18n.t('date-format.date')
                            ),
                            action: proposal.closed_by === proposal.user.id ? $t('withdrawn').toLowerCase() : $t('rejected').toLowerCase()
                        }) }}
                    </closed-message>
                </div>
            </template>

            <template v-if="isAssignment">
                <assignment-meta :assignment="assignment" />
            </template>

            <template v-if="hasAttachments">
                <ul class="list--clean">
                    <li
                        v-for="attachment in message.media"
                        :key="attachment.id"
                    >
                        <file-download
                            :attachment="attachment"
                            :href="getAttachmentUrl(attachment.id)"
                        />
                    </li>
                </ul>
            </template>
        </template>
    </article>

    <footer
        v-if="message.type !== 'notice'"
        class="message__footer"
    >
        <div
            v-if="message[authorProperty] && avatarPosition === 'below'"
            class="message__profile-picture"
        >
            <avatar
                class="avatar--small"
                size="small"
                :user="message[authorProperty]"
            />
        </div>
        <div class="message__meta">
            <slot
                name="meta"
                :message="message"
            >
                <span
                    v-if="!isMe && message.user"
                    class="message__author"
                >
                    {{ message.user.full_name }} <colleague-icon v-if="hasClientRole && !isMe" />
                </span>

                <time class="message__time">
                    <template v-if="!isMe">
                        -
                    </template>

                    <template v-if="message.created_at">
                        {{ message.created_at | humanDateTime }}
                    </template>

                    <template v-if="isAssignment && assignment.isEdited && assignment.updated_at">
                        - {{ $t('message.updated', {
                            datetime: $options.filters.humanDateTime(
                                assignment.updated_at
                            )
                        }) }}
                    </template>
                </time>
            </slot>
        </div>
    </footer>
</div>
