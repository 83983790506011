<template>
    <dn-modal
        v-if="!loading && assignment"
        name="create-proposal"
        size="large"
        position="centered"
        :close-prompt="$t('modal.create-proposal.close-prompt')"
    >
        <modal-content class="modal-content--form">
            <h1 class="text-center">
                {{ $t('modal.create-proposal.modal-title') }}

                <span
                    v-if="isDraft"
                    class="text--muted"
                >
                    {{ $t('modal.create-proposal.draft') }}
                </span>
            </h1>

            <a
                class="create-proposal__assignment-link"
                :href="`/assignment/${assignment.id}/thread/${threadId}/detail`"
                target="_blank"
            >
                {{ $t('actions.view-assignment') }}: {{ assignment.title }}
                <svg-icon icon="external-link" />
            </a>

            <form
                class="form"
                data-vv-scope="proposal-create"
                @submit.prevent="submitProposal"
            >
                <llq-notice
                    v-if="isPrefillData"
                    severity="warning"
                    style="margin-bottom: 1rem;"
                >
                    <div>{{ $t('modal.create-proposal.prefill-notice') }}</div>
                    <div>{{ $t('modal.create-proposal.prefill-notice-attachment-supplement') }}</div>
                </llq-notice>

                <form-field
                    v-if="!isHidden('APPROACH')"
                    :error-messages="errors.collect('proposal-create.content')"
                    :required="isRequired('APPROACH')"
                >
                    <label
                        slot="label"
                        for="createProposalContent"
                    >
                        {{ $t('proposalView.content') }}
                    </label>

                    <span
                        v-if="hasDescription('APPROACH')"
                        slot="description"
                    >
                        {{ getDescription('APPROACH') }}
                    </span>

                    <MarkupTextarea
                        id="createProposalContent"
                        v-model="formData.content"
                        v-validate="{ required: isRequired('APPROACH') }"
                        name="content"
                        rows="6"
                        :placeholder="$t('modal.create-proposal.content.placeholder')"
                    />
                </form-field>

                <form-field
                    v-if="!isHidden('START_DATE')"
                    :error-messages="errors.collect('proposal-create.starts_at')"
                    :required="isRequired('START_DATE')"
                    columns-xs-min="12/12"
                    columns-md-min="6/12"
                >
                    <label
                        slot="label"
                        for="createProposalStartDate"
                    >
                        {{ $t('proposalView.starts_at') }}
                    </label>

                    <span
                        v-if="hasDescription('START_DATE')"
                        slot="description"
                    >
                        {{ getDescription('START_DATE') }}
                    </span>

                    <datepicker
                        id="createProposalStartDate"
                        v-model="formData.starts_at"
                        v-validate="{ required: isRequired('START_DATE') }"
                        name="starts_at"
                        :placeholder="$t('modal.create-proposal.starts_at.placeholder')"
                    />
                </form-field>

                <form-section
                    v-if="!isHidden('TARIFF_ANY')"
                >
                    <template #header>
                        {{ $t('modal.create-proposal.tariff.title') }}
                        <span
                            v-if="!isRequired('TARIFF_ANY')"
                            class="text--muted"
                        >
                            ({{ $t('general.optional') }})
                        </span>
                    </template>

                    <template #description>
                        <span v-if="hasDescription('TARIFF_ANY')">{{ getDescription('TARIFF_ANY') }}<br></span>
                    </template>

                    <div
                        v-if="addedPercentagePoints > 0 && !userIsClientSourcedSupplier"
                        class="form__message"
                    >
                        {{ $t('modal.create-proposal.tariff.fee_message', { fee: 100 - addedPercentagePoints}) }}
                    </div>

                    <repeater
                        :items="formData.tariffs"
                        columns
                    >
                        <template #items="{ index }">
                            <form-field
                                columns-xs-min="12/12"
                                columns-md-min="7/12"
                                :error-messages="errors.collect('proposal-create.tariffs')"
                                :required="isRequired('TARIFF_ANY') || formData.tariffs[index].amount !== ''"
                            >
                                <label slot="label">{{ $t('modal.create-proposal.tariff.label.type') }}</label>
                                <input
                                    v-model="formData.tariffs[index].type"
                                    v-validate="{ required: isRequired('TARIFF_ANY') || formData.tariffs[index].amount !== '' }"
                                    type="text"
                                    name="tariff_type"
                                    :placeholder="$t('modal.create-proposal.tariff.placeholder.type')"
                                >
                            </form-field>

                            <form-field
                                columns-xs-min="12/12"
                                columns-md-min="3/12"
                                :error-messages="errors.collect('proposal-create.tariffs')"
                                :required="isRequired('TARIFF_ANY') || formData.tariffs[index].type !== ''"
                                icon-position="left"
                            >
                                <template v-slot:icon>€</template>

                                <label slot="label">{{ $t('modal.create-proposal.tariff.label.value') }}</label>

                                <input
                                    v-model="formData.tariffs[index].amount"
                                    v-validate="`${(isRequired('TARIFF_ANY') || formData.tariffs[index].type) ? 'required|' : ''}decimal:2`"
                                    type="number"
                                    max="999999"
                                    :lang="$i18n.locale"
                                    name="tariff"
                                    :placeholder="$n('0.00', { minimumFractionDigits: 2 })"
                                >
                            </form-field>
                        </template>

                        <template #info="{ index }">
                            <tariff-calculation :tariff="formData.tariffs[index].amount" />
                        </template>
                    </repeater>

                    <note-field v-model="formData.tariff_note" />
                </form-section>

                <form-section
                    v-if="!isHidden('WORKLOAD_ANY')"
                >
                    <template #header>
                        {{ $t('modal.create-proposal.workload.title') }}
                        <span
                            v-if="!isRequired('WORKLOAD_ANY')"
                            class="text--muted"
                        >
                            ({{ $t('general.optional') }})
                        </span>
                    </template>

                    <template
                        #description
                    >
                        <span v-if="hasDescription('WORKLOAD_ANY')">{{ getDescription('WORKLOAD_ANY') }}</span>
                        <span
                            v-else
                        >
                            {{ $t('modal.create-proposal.workload.description') }}
                        </span>
                    </template>

                    <div
                        v-if="addedPercentagePoints > 0 && !userIsClientSourcedSupplier"
                        class="form__message"
                    >
                        {{ $t('modal.create-proposal.workload.fee_message', { fee: 100 - addedPercentagePoints}) }}
                    </div>

                    <div class="form-field">
                        <radio
                            v-model="formData.workload_type"
                            class="radio--inline"
                            name="type-radio"
                            value="total"
                        >
                            {{ $t('modal.create-proposal.workload.label.total') }}
                        </radio>

                        <radio
                            v-model="formData.workload_type"
                            class="radio--inline"
                            name="type-radio"
                            value="range"
                        >
                            {{ $t('modal.create-proposal.workload.label.range') }}
                        </radio>
                    </div>

                    <repeater
                        :items="formData.workloads"
                        columns
                        @input="workloadsChange"
                    >
                        <template #items="{ index }">
                            <form-field
                                columns-xs-min="12/12"
                                :columns-md-min="formData.workload_type === 'range' ? '12/12' : '7/12'"
                                :columns-lg-min="formData.workload_type === 'range' ? '4/12' : '7/12'"
                                :error-messages="errors.collect('proposal-create.tariffs')"
                                :required="isRequired('WORKLOAD_ANY') || formData.workloads[index].total_estimate !== '' || formData.workloads[index].lower_boundary !== '' || formData.workloads[index].upper_boundary !== ''"
                            >
                                <label slot="label">{{ $t('modal.create-proposal.workload.label.description') }}</label>
                                <input
                                    v-model="formData.workloads[index].description"
                                    v-validate="{ required: isRequired('WORKLOAD_ANY') || formData.workloads[index].total_estimate !== '' }"
                                    type="text"
                                    name="workload"
                                    :readonly="formData.workloads[index].isPrefilled === true"
                                    :placeholder="$t('modal.create-proposal.workload.placeholder.description')"
                                >
                            </form-field>

                            <template v-if="formData.workload_type === 'range'">
                                <form-field
                                    columns-xs-min="12/12"
                                    columns-md-min="4/12"
                                    columns-lg-min="3/12"
                                    :required="isRequired('WORKLOAD_ANY') && formData.workload_type === 'range'"
                                    :error-messages="errors.collect('proposal-create.tariffs')"
                                    icon-position="left"
                                >
                                    <template v-slot:icon>€</template>

                                    <label slot="label">{{ $t('modal.create-proposal.workload.label.lower_boundary') }}</label>

                                    <input
                                        v-model="formData.workloads[index].lower_boundary"
                                        v-validate="`${(isRequired('WORKLOAD_ANY') && formData.workload_type === 'range') ? 'required|' : ''}decimal:2`"
                                        type="number"
                                        name="workload_lower"
                                        max="999999"
                                        min="0"
                                        :lang="$i18n.locale"
                                        :placeholder="$n('0.00', { minimumFractionDigits: 2 })"
                                    >
                                </form-field>

                                <form-field
                                    columns-xs-min="12/12"
                                    columns-md-min="4/12"
                                    columns-lg-min="3/12"
                                    :error-messages="errors.collect('proposal-create.tariffs')"
                                    :required="isRequired('WORKLOAD_ANY') && formData.workload_type === 'range'"
                                    icon-position="left"
                                >
                                    <template v-slot:icon>€</template>

                                    <label slot="label">{{ $t('modal.create-proposal.workload.label.upper_boundary') }}</label>

                                    <input
                                        v-model="formData.workloads[index].upper_boundary"
                                        v-validate="`${(isRequired('WORKLOAD_ANY') && formData.workload_type === 'range') ? 'required|' : ''}decimal:2`"
                                        type="number"
                                        name="workload_upper"
                                        max="999999"
                                        min="0"
                                        :lang="$i18n.locale"
                                        :placeholder="$n('0.00', { minimumFractionDigits: 2 })"
                                    >
                                </form-field>
                            </template>
                            <template v-else>
                                <form-field
                                    columns-xs-min="12/12"
                                    columns-md-min="3/12"
                                    columns-lg-min="3/12"
                                    :error-messages="errors.collect('proposal-create.tariffs')"
                                    :required="isRequired('WORKLOAD_ANY') && formData.workload_type === 'total'"
                                    icon-position="left"
                                >
                                    <template v-slot:icon>€</template>

                                    <label slot="label">{{ $t('modal.create-proposal.workload.label.total_estimate') }}</label>

                                    <input
                                        v-model="formData.workloads[index].total_estimate"
                                        v-validate="`${(isRequired('WORKLOAD_ANY') && formData.workload_type === 'total') ? 'required|' : ''}decimal:2`"
                                        name="workload_total"
                                        type="number"
                                        max="999999"
                                        min="0"
                                        :lang="$i18n.locale"
                                        :placeholder="$n('0.00', { minimumFractionDigits: 2 })"
                                    >
                                </form-field>
                            </template>
                        </template>

                        <template #info="{ index }">
                            <note-field
                                :key="`workloadNote_${index}`"
                                v-model="formData.workloads[index].remarks"
                                class="note-field--inline"
                                :force-active.sync="formData.workloads[index].hasRemarks"
                                :with-toggle="false"
                            />
                        </template>

                        <template #buttons="{ index }">
                            <v-button
                                v-if="formData.workloads[index].hasRemarks !== true"
                                class="repeater__button"
                                @click.prevent="formData.workloads[index].hasRemarks = true"
                            >
                                <svg-icon
                                    icon="note-plus"
                                />
                            </v-button>

                            <v-button
                                v-else
                                class="repeater__button"
                                @click.prevent="formData.workloads[index].hasRemarks = false"
                            >
                                <svg-icon icon="chat-error" />
                            </v-button>
                        </template>
                    </repeater>

                    <tariff-calculation-total
                        :type="formData.workload_type"
                        :workloads="formData.workloads"
                    />

                    <note-field v-model="formData.workload_note" />
                </form-section>

                <form-section
                    v-if="!isHidden('FIXED_FEE')"
                >
                    <div class="column-grid">
                        <form-field
                            columns-xs-min="12/12"
                            columns-md-min="6/12"
                            :error-messages="errors.collect('proposal-create.fixed_fee')"
                            :required="isRequired('FIXED_FEE')"
                        >
                            <template #description>
                                <span v-if="hasDescription('FIXED_FEE')">{{ getDescription('FIXED_FEE') }}<br></span>
                                <span
                                    v-if="addedPercentagePoints > 0 && !userIsClientSourcedSupplier"
                                    class="form__message"
                                >
                                    {{ $t('modal.create-proposal.tariff.fee_message', { fee: 100 - addedPercentagePoints}) }}
                                </span>
                            </template>

                            <template v-slot:icon>€</template>

                            <label slot="label">{{ $t('modal.create-proposal.fixed_fee.label') }}</label>

                            <input
                                v-model="formData.fixed_fee_amount"
                                v-validate="`${(isRequired('FIXED_FEE')) ? 'required|' : ''}decimal:2`"
                                name="fixed_fee"
                                type="number"
                                min="0"
                                :lang="$i18n.locale"
                                :placeholder="$n('0.00', { minimumFractionDigits: 2 })"
                            >
                        </form-field>
                    </div>

                    <tariff-calculation
                        class="tariff-calculation--fullwidth"
                        :tariff="formData.fixed_fee_amount"
                    />

                    <note-field v-model="formData.fixed_fee_note" />
                </form-section>

                <form-section
                    v-if="!isHidden('AVAILABILITY_ANY')"
                >
                    <template #header>
                        {{ $t('modal.create-proposal.availability.title') }}
                    </template>

                    <template
                        #description
                    >
                        <span v-if="hasDescription('AVAILABILITY_ANY')">{{ getDescription('AVAILABILITY_ANY') }}</span>
                        <span
                            v-else
                        >
                            {{ $t('modal.create-proposal.availability.description') }}
                        </span>
                    </template>

                    <div class="column-grid">
                        <form-field
                            columns-xs-min="12/12"
                            columns-md-min="2/12"
                            :error-messages="errors.collect('proposal-create.availability')"
                            :required="isRequired('AVAILABILITY_ANY')"
                        >
                            <label slot="label">{{ $t('modal.create-proposal.availability.label.amount') }}</label>
                            <input
                                v-model="formData.availability_amount"
                                v-validate="{ required: isRequired('AVAILABILITY_ANY') }"
                                name="availability"
                                type="number"
                                min="0"
                            >
                        </form-field>

                        <form-field
                            columns-xs-min="12/12"
                            columns-md-min="5/12"
                            :error-messages="errors.collect('proposal-create.availability')"
                            :required="isRequired('AVAILABILITY_ANY')"
                        >
                            <label slot="label">{{ $t('modal.create-proposal.availability.label.unit') }}</label>
                            <multiselect
                                v-model="formData.availability_per_time_unit"
                                v-validate="{ required: isRequired('AVAILABILITY_ANY') }"
                                :options="availabilityDurationOptions"
                                :searchable="false"
                                :show-labels="false"
                                :multiple="false"
                                :allow-empty="isRequired('AVAILABILITY_ANY')"
                                :custom-label="availabilityDurationLabel"
                            />
                        </form-field>
                    </div>

                    <note-field v-model="formData.availability_note" />
                </form-section>

                <form-section
                    v-if="!isHidden('MEDIA')"
                >
                    <form-field
                        :required="isRequired('MEDIA')"
                        :error-messages="errors.collect('proposal-create.media')"
                        columns-xs-min="12/12"
                    >
                        <template #label>
                            {{ $t('modal.create-proposal.media.title') }}
                        </template>

                        <template
                            v-if="hasDescription('MEDIA')"
                            #description
                        >
                            <span>{{ getDescription('MEDIA') }}</span>
                        </template>
                        <div
                            v-for="(media, key) in formData.media"
                            :key="key"
                        >
                            <file-attach
                                v-if="formData.media[key] !== null"
                                v-model="formData.media[key]"
                                v-validate="{ required: isRequired('MEDIA') && !hasAnyMedia }"
                                name="media"
                            />

                            <!--Extra `file-attach` for 'next attachment'-->
                            <file-attach
                                v-if="key === formData.media.length - 1"
                                v-model="formData.media[key + 1]"
                                v-validate="{ required: isRequired('MEDIA') && !hasAnyMedia }"
                                name="media"
                            />
                        </div>
                    </form-field>
                </form-section>

                <form-section
                    v-if="!isHidden('RESUME')"
                >
                    <form-field
                        columns-xs-min="12/12"
                        :required="isRequired('RESUME')"
                        :error-messages="errors.collect('proposal-create.cv')"
                    >
                        <template
                            v-if="hasDescription('RESUME')"
                            #description
                        >
                            <span>{{ getDescription('RESUME') }}</span>
                        </template>
                        <template #label>
                            {{ $t('modal.create-proposal.cv.title') }}
                            <span
                                v-if="!isRequired('RESUME')"
                                class="muted"
                            >
                                ({{ $t('general.optional') }})
                            </span>
                        </template>
                        <file-attach
                            v-model="formData.cv"
                            v-validate="{ required: isRequired('RESUME') }"
                            name="cv"
                        />
                    </form-field>
                </form-section>

                <template v-if="openQuestions.length && formData.open_questions_answers.length">
                    <form-field
                        v-for="(question, index) in openQuestions"
                        :key="question.id"
                        :required="question.required"
                        :error-messages="errors.collect(`proposal-create.open-questions-${question.id}`)"
                    >
                        <label
                            slot="label"
                            for="createProposalDescription"
                        >
                            {{ question.label }} {{ question.required ? '*' : '' }}
                        </label>

                        <MarkupTextarea
                            id="createProposalDescription"
                            v-model="formData.open_questions_answers[index].value"
                            v-validate="{ required: question.required }"
                            :name="`open-questions-${question.id}`"
                            rows="3"
                        />
                    </form-field>
                </template>

                <form-field
                    :error-messages="errors.collect('proposal-create.remarks')"
                >
                    <label
                        slot="label"
                        for="createProposalDescription"
                    >
                        {{ $t('proposalView.remarks') }}
                    </label>

                    <MarkupTextarea
                        id="createProposalDescription"
                        v-model="formData.remarks"
                        name="remarks"
                        rows="3"
                    />
                </form-field>

                <form-section
                    v-if="errors.items.length > 0"
                    class="form-section--errors"
                >
                    <template v-if="errors.items.filter((error) => error.field !== null && error.rule === 'required').length > 0">
                        <h5>
                            {{ $t('proposalView.error-message.required') }}
                        </h5>
                        <ul>
                            <li
                                v-for="error in errors.items.filter((error) => error.field !== null && error.rule === 'required')"
                                :key="error.id"
                            >
                                {{ $t(`proposalView.${error.field}`) }}
                            </li>
                        </ul>
                    </template>

                    <template v-if="errors.items.filter((error) => error.field !== null && error.rule !== 'required').length > 0">
                        <h5>
                            {{ $t('proposalView.error-message.wrong-input') }}
                        </h5>
                        <ul>
                            <li
                                v-for="error in errors.items.filter((error) => error.field !== null && error.rule !== 'required')"
                                :key="error.id"
                            >
                                {{ $t(`proposalView.${error.field}`) }}
                            </li>
                        </ul>
                    </template>
                </form-section>

                <form-field class="form-field--last">
                    <v-button
                        class="btn--secondary btn--wide"
                        :state="requestState"
                        @click="submitProposal"
                    >
                        {{ $t('modal.create-proposal.cta') }}
                    </v-button>
                </form-field>
            </form>
        </modal-content>
    </dn-modal>
</template>

<script>
'use strict';

import Vue from 'vue';
import { mapGetters } from 'vuex';
import Multiselect from 'vue-multiselect';
import Datepicker from 'vuejs-datepicker';

import CreateProposalMutation from '~/graphql/mutations/createProposal.graphql';
import AssignmentQuery from '~/graphql/queries/assignment/AssignmentProposalInfo.graphql';
import AssignmentProposalsQuery from '~/graphql/queries/assignment/AssignmentProposals.graphql';

import ProposalFees from '~/helpers/proposal-fees';
import { TARIFF_TYPE_HOURLY, TARIFF_TYPE_TOTAL } from '~/constants/tariff-types';
import { AVAILABILITY_PER_TIME_UNITS } from '~/constants/availability-duration-units';

import LlqNotice from '~/patterns/atoms/notice/notice.vue';

import DnModal from '~/patterns/organisms/modal/modal.vue';
import ModalContent from '~/patterns/organisms/modal-content/modal-content.vue';

import FormSection from '~/patterns/molecules/form-section/form-section.vue';
import TariffCalculation from '~/patterns/molecules/tariff-calculation/tariff-calculation.vue';
import TariffCalculationTotal from '~/patterns/molecules/tariff-calculation-total/tariff-calculation-total.vue';
import FormField from '~/patterns/molecules/form-field/form-field.vue';
import NoteField from '~/patterns/molecules/note-field/note-field.vue';
import Repeater from '~/patterns/molecules/repeater/repeater.vue';
import VButton from '~/patterns/atoms/button/button.vue';
import Radio from '~/patterns/atoms/radio/radio.vue';
import FileAttach from '~/patterns/atoms/file-attach/file-attach.vue';
import { EVENT_PROPOSAL_CREATED } from '~/constants/events';
import Bugsnag from '@bugsnag/js';

export default {
    components: {
        LlqNotice,
        DnModal,
        ModalContent,
        FormField,
        Multiselect,
        Datepicker,
        VButton,
        Radio,
        NoteField,
        Repeater,
        TariffCalculation,
        TariffCalculationTotal,
        FileAttach,
        FormSection,
        MarkupTextarea: () => import('~/patterns/molecules/markup-textarea/markup-textarea.vue'),
    },

    data() {
        return {
            assignment: null,
            loading: false,
            draft: false,

            icons: 'external-link',

            tariffTypeOptions: [
                TARIFF_TYPE_TOTAL,
                TARIFF_TYPE_HOURLY
            ],
            availabilityDurationOptions: AVAILABILITY_PER_TIME_UNITS,

            /* eslint-disable camelcase */
            formData: {
                content: null,
                status: 'proposal',
                starts_at: null,
                remarks: null,
                tariffs: [{ type: '', amount: '' }],
                tariff_note: null,
                workloads: [
                    {
                        description: '',
                        remarks: '',
                        hasRemarks: false,
                        total_estimate: '',
                        upper_boundary: '',
                        lower_boundary: ''
                    }
                ],
                workload_type: 'total',
                workload_note: null,
                availability_amount: null,
                availability_note: null,
                availability_per_time_unit: 'hoursPerWeek',
                fixed_fee_amount: null,
                fixed_fee_note: null,
                media: [null],
                cv: null,
                open_questions_answers: []
            },
            /* eslint-enable camelcase */
            requestState: undefined
        };
    },

    computed: {
        ...mapGetters({
            loggedInUser: 'auth/user',
            isOpen: 'modal/isOpen',
            modalData: 'modal/data'
        }),

        modalOpen() {
            return this.isOpen('create-proposal');
        },

        assignmentTitle() {
            return this.assignment?.title;
        },

        threadId() {
            if (this.modalData && this.modalData.threadId) {
                return this.modalData.threadId;
            } else {
                return this.$route.params.threadId;
            }
        },

        assignmentId() {
            if (this.modalData && this.modalData.assignmentId) {
                return this.modalData.assignmentId;
            } else {
                return this.$route.params.assignmentId;
            }
        },

        addedPercentagePoints() {
            return ProposalFees.getLawyerlinqFeePercentage(this.loggedInUser);
        },

        hasAnyMedia() {
            return !!this.formData.media.filter(attachment => attachment !== null).length;
        },

        isDraft() {
            return Boolean(this?.modalData?.draft);
        },

        proposalPrefilled() {
            const data = this.modalData?.prefillData;

            if (data && typeof data !== 'undefined') {
                let workloadType = 'total'; // default workload type

                const tariffs = data.tariffs?.length ? data.tariffs.map((tariff) => {
                    return {
                        type: tariff.type,
                        amount: (tariff.amount).toString()
                    };
                }) : [{ type: '', amount: '' }];

                const workloads = data.workloads?.length ? data.workloads.map((workload) => {
                    workloadType = workload.type;

                    return {
                        description: workload.description,
                        remarks: workload.remarks,
                        hasRemarks: workload.remarks?.length > 0,
                        'total_estimate': workload.total_estimate !== null ? (workload.total_estimate).toString() : '',
                        'upper_boundary': workload.upper_boundary !== null ?(workload.upper_boundary).toString() : '',
                        'lower_boundary': workload.lower_boundary !== null ? (workload.lower_boundary).toString() : '',
                    };
                }) : [
                    {
                        description: '',
                        remarks: '',
                        hasRemarks: false,
                        'total_estimate': '',
                        'upper_boundary': '',
                        'lower_boundary': '',
                    }
                ];

                // Merge in metadata from the propposal_form_config
                if (this.assignment?.proposal_form_config?.workload_fields?.length) {
                    this.assignment.proposal_form_config.workload_fields.forEach((workloadField) => {
                        // Match by label, as the index might be different
                        const existingWorkload = workloads.find((workload) => workload.description === workloadField.label);
                        const existingWorkloadIndex = workloads.findIndex((workload) => workload.description === workloadField.label);
                        if (existingWorkload) {
                            // @TODO values are missing?
                            existingWorkload.required = workloadField.required;
                            existingWorkload.isPrefilled = true; // mark as non-editable label
                        } else {
                            // Add missing workload_fields to workloads at the array index as it had in workload_fields
                            workloads.splice(existingWorkloadIndex, 0, {
                                description: workloadField.label,
                                required: workloadField.required,
                                isPrefilled: true
                            });
                        }
                    });
                }

                // Fill the value in formData.open_questions_answers with any existing answers from data.open_questions_answers if they have the same label
                const openQuestions = this.openQuestions.map((question) => {
                    const prefilled = data.open_questions_answers.find((answer) => answer.id === question.id);
                    return {
                        label: question.label,
                        value: prefilled?.value || '',
                        id: question.id
                    };
                });

                return {
                    content: data.content || null,
                    'starts_at': data.starts_at || null,
                    remarks: data.remarks || null,
                    tariffs,
                    'tariff_note': data.tariff_note || null,
                    workloads,
                    'workload_type': workloadType,
                    'workload_note': data.workload_note || null,
                    'availability_amount': data.availability_amount || null,
                    'availability_note': data.availability_note || null,
                    'availability_per_time_unit': data.availity_per_time_unit || 'hoursPerWeek',
                    'fixed_fee_amount': data.fixed_fee_amount || null,
                    'fixed_fee_note': data.fixed_fee_note || null,
                    media: [null],
                    // eslint-disable-next-line camelcase
                    open_questions_answers: openQuestions,
                    cv: null
                };
            } else {
                return [];
            }
        },

        isPrefillData() {
            return this.modalData?.prefillData &&
                JSON.stringify(this.formData) === JSON.stringify(this.proposalPrefilled);
        },

        openQuestions() {
            return this.assignment?.proposal_form_config?.open_questions?.length ?
                this.assignment.proposal_form_config.open_questions :
                [];
        },

        userIsClientSourcedSupplier() {
            return this.loggedInUser.roles.includes('supplier')
                && this.loggedInUser.source === 'client';
        }
    },

    watch: {
        async assignmentId(value) {
            // (Re-)fetch assignment data if needed
            if (value && (!this.assignment || this.assignment.id !== this.assignmentId)) {
                this.loading = true;
                const { data } = await this.$executeGqlQuery(AssignmentQuery, { id: this.assignmentId });
                this.assignment = data.assignment;
                // eslint-disable-next-line camelcase
                this.formData.open_questions_answers = data.assignment.proposal_form_config?.open_questions?.map(({id, label}) => {
                    return { id, label, value: ''};
                }) || [];
                if (data.assignment.proposal_form_config?.workload_fields?.length) {
                    this.formData.workloads = data.assignment.proposal_form_config.workload_fields.map(({label, required}) => {
                        return { description: label, required, isPrefilled: true, hasRemarks: false };
                    }) || [];
                }
                this.loading = false;
            }
        },

        'formData.workload_type': {
            handler() {
                this.$validator.reset();
            }
        },

        modalOpen: {
            handler(newValue) {
                // on modal close:
                if (newValue === false) {
                    this.$validator.reset();
                    this.resetFields();
                    this.requestState = undefined;
                }

                if (this.modalData?.prefillData) {
                    this.prefillProposal(this.modalData.prefillData);
                }

                this.setStatus(this.modalData?.draft ? 'draft' : 'proposal');
            },
            immediate: true
        }
    },

    methods: {
        workloadsChange(workloads) {
            // When something is mutated via the repeater, set a default value for the remarks/note state
            Vue.set(this.formData, 'workloads', workloads.map((workload) => {
                return {
                    ...workload,
                    hasRemarks: workload.hasRemarks === undefined ? false : workload.hasRemarks
                };
            }));
        },

        isRequired(proposalInformationType) {
            if (proposalInformationType === 'WORKLOAD_ANY') {
                const requiredRow = this.assignment?.proposal_form_config.workload_fields?.some(workload => workload.required);
                if (requiredRow) {
                    return true;
                }
                // Continue checking...
            }
            if (!this.assignment?.proposal_form_config.required_fields) {
                return false;
            } else {
                return this.assignment.proposal_form_config.required_fields.includes(proposalInformationType);
            }
        },

        isHidden(proposalInformationType) {
            if (!this.assignment?.proposal_form_config.hidden_fields) {
                return false;
            } else {
                return this.assignment.proposal_form_config.hidden_fields.includes(proposalInformationType) &&
                    !this.isRequired(proposalInformationType);
            }
        },

        hasDescription(proposalInformationType) {
            if (!this.assignment?.proposal_form_config.field_descriptions) {
                return false;
            } else {
                return this.assignment.proposal_form_config.field_descriptions.filter(
                    ({ field, description }) => field === proposalInformationType && description?.length
                ).length > 0;
            }
        },

        getDescription(proposalInformationType) {
            if (!this.hasDescription(proposalInformationType)) {
                return undefined;
            } else {
                return this.assignment.proposal_form_config.field_descriptions.filter(
                    ({ field }) => field === proposalInformationType
                )[0].description;
            }
        },

        submitProposal() {
            this.$validator.validateAll('proposal-create').then((noErrors) => {
                if (!noErrors) {
                    this.requestState = 'fail';
                    return;
                }

                this.createProposal();
            });
        },

        async createProposal() {
            this.requestState = 'loading';

            await this.closeAllProposals();
            const proposalData = this.getVariables();

            await this.closeAllProposals();

            try {
                const { data } = await this.$executeGqlQuery(CreateProposalMutation, { input: proposalData });
                setTimeout(() => {
                    this.$store.dispatch('modal/closeModal');
                    this.$store.dispatch('modal/openModal', 'proposal-created');
                    this.requestState = 'success';
                    this.resetFields();
                    this.$eventBus.$emit(EVENT_PROPOSAL_CREATED);
                    this.$router.push({
                        name: 'proposal.detail',
                        params: { id: data.createProposal.id },
                    });
                }, 1000);

            } catch (e) {
                this.requestState = 'fail';
                // eslint-disable-next-line no-console
                Bugsnag.notify(e);
                console.error(e);
            }
        },
        // Todo - move to the backend Event Service
        async closeAllProposals() {
            // Get all proposals for this assignment
            const { data } = await this.$executeGqlQuery(AssignmentProposalsQuery, {
                assignmentId: this.assignmentId,
                threadId: this.threadId,
            });
            const proposals = data.assignment.proposals;

            const filteredProposals = proposals.filter(proposal => !['draft', 'send-draft'].includes(proposal.status));

            // Close all proposals --except the one that is being created--
            const proposalIds = filteredProposals.map(proposal => proposal.id);

            proposalIds.forEach(proposalId => {
                this.$store.dispatch('proposals/closeProposal', proposalId);
            });
        },

        prefillProposal() {
            this.formData = { ...this.proposalPrefilled };
        },

        setStatus(status) {
            this.formData.status = status;
        },

        getVariables() {
            const populatedMedia = this.formData.media.filter(attachment => attachment !== null);
            const tariffs = this.formData.tariffs.filter(tariff => tariff.amount !== '' && tariff.type !== '')?.map(tariff => {
                return {
                    type: tariff.type,
                    amount: Number(tariff.amount)
                };
            });
            const workloads = this.formData.workloads.filter(
                workload => workload.description !== '' && this.formData.workload_type === 'total'
                    ? workload.total_estimate !== ''
                    : workload.lower_boundary !== '' && workload.upper_boundary !== ''
            )?.map(workload => {
                return {
                    description: workload.description,
                    remarks: workload.remarks,
                    // eslint-disable-next-line camelcase
                    lower_boundary: workload.lower_boundary ? Number(workload.lower_boundary) : null,
                    // eslint-disable-next-line camelcase
                    upper_boundary: workload.upper_boundary ? Number(workload.upper_boundary) : null,
                    // eslint-disable-next-line camelcase
                    total_estimate: workload.total_estimate ? Number(workload.total_estimate) : null,
                };
            }
            );

            // Return proposal data
            return {
                assignment_id: this.assignmentId,
                status: this.formData.status,
                thread_id: this.threadId,
                content: this.formData.content,
                starts_at: this.formData.starts_at ? this.$options.filters.dateFormat(this.formData.starts_at, this.$t('date-format.datepicker')) : null,
                remarks: this.formData.remarks,
                availability_per_time_unit: this.formData.availability_per_time_unit,
                availability_amount: this.formData.availability_amount ? Number(this.formData.availability_amount) : null,
                availability_note: this.formData.availability_note,
                fixed_fee_amount: this.formData.fixed_fee_amount ? Number(this.formData.fixed_fee_amount) : null,
                fixed_fee_note: this.formData.fixed_fee_note,
                tariffs,
                tariff_note: this.formData.tariff_note,
                media: populatedMedia.length ? populatedMedia : null,
                workloads,
                workload_note: this.formData.workload_note,
                workload_type: this.formData.workload_type,
                open_questions_answers: this.formData.open_questions_answers.filter(question => question.value?.length),
                cv: this.formData.cv
            };
        },

        getInitialWorkloads() {
            const prefilled = this.assignment?.proposal_form_config?.workload_fields?.map(({label, required}) => {
                return { description: label, required, isPrefilled: true, hasRemarks: false };
            });
            return prefilled?.length ? prefilled : [{
                description: '',
                remarks: '',
                hasRemarks: false,
                total_estimate: '',
                upper_boundary: '',
                lower_boundary: '',
            }];
        },

        resetFields() {
            this.formData = {
                content: null,
                starts_at: null,
                remarks: null,
                tariffs: [{ type: '', amount: '' }],
                tariff_note: null,
                workloads: this.getInitialWorkloads(),
                total_estimate: 0,
                upper_boundary: null,
                lower_boundary: null,
                availability_amount: null,
                availability_note: null,
                fixed_fee_amount: null,
                fixed_fee_note: null,
                availability_per_time_unit: 'hoursPerWeek',
                media: [null],
                cv: null,
                open_questions_answers: this.formData.open_questions_answers.map((question) => {
                    return {...question, value: '' };
                }),
                workload_type: 'total',
            };
        },

        resetWorkloads(value) {
            if (value !== this.modalData?.prefillData?.workloads[0]?.type) {
                this.formData.workloads = [
                    {
                        description: '',
                        remarks: '',
                        hasRemarks: false,
                        total_estimate: '',
                        upper_boundary: '',
                        lower_boundary: '',
                    }
                ];
            }
        },
        /* eslint-enable camelcase */

        tariffTypeLabel(option) {
            return this.$i18n.t(`tariffTypes.${option}`);
        },

        availabilityDurationLabel(option) {
            return this.$i18n.t(`availabilityDurationUnits.${option}`);
        }
    }
};
</script>
<style lang="less">
.create-proposal__assignment-link {
    display: inline-block;
    margin-right: .75rem;

    .icon {
        position: relative;
        top: 2px;
    }

    @media @q-lg-min {
        display: block;
        margin-bottom: 2rem;
    }
}
</style>
