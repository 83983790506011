
<dn-modal
    name="proposal-accept-dialog"
    size="medium"
>
    <modal-content>
        <section>
            <h1>{{ $t('modal.accept-proposal-dialog.title') }}</h1>

            <p>{{ $t('modal.proposal-accept-dialog.description') }}</p>

            <p class="text-right">
                <v-button
                    class="btn btn--inline"
                    @click="accept(false)"
                >
                    {{ $t('modal.accept-proposal-dialog.cancel') }}
                </v-button>

                <v-button
                    class="btn btn--secondary btn--inline"
                    @click="accept(true)"
                >
                    {{ $t('modal.accept-proposal-dialog.accept') }}
                </v-button>
            </p>
        </section>
    </modal-content>
</dn-modal>
