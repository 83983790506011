<!-- eslint-disable vue/no-v-html -->
<template>
    <ul
        v-if="proposal"
        class="meta-list proposal-meta"
    >
        <li
            v-if="proposal.status === 'draft'"
            class="proposal-meta__status"
        >
            <span class="tag">
                {{ $t('proposalView.status-draft') }}
            </span>
        </li>

        <li
            v-if="proposal.status === 'send-draft'"
            class="proposal-meta__status"
        >
            <span class="tag">
                {{ $t('proposalView.status-send-draft') }}
            </span>
        </li>

        <li
            v-if="isCommunityManagerProposal"
            class="proposal-meta__tag"
        >
            <span class="tag">
                {{ $t('proposalView.community-manager') }}
            </span>
        </li>

        <li
            v-if="proposal.content"
            class="meta-list__item"
        >
            <h3>
                <svg-icon
                    class="meta-list__icon"
                    icon="note"
                />
                {{ $t('proposalView.approach') }}
            </h3>

            <p
                v-if="proposal.description?.content"
                class="meta-list__description"
            >
                {{ proposal.description?.content }}
            </p>

            <div v-html="proposal.content" />
        </li>

        <li
            v-if="proposal.starts_at"
            class="meta-list__item"
        >
            <h3>
                <svg-icon
                    class="meta-list__icon"
                    icon="calendar"
                />
                {{ $t('proposalView.starts_at') }}
            </h3>
            <p
                v-if="proposal.description?.starts_at"
                class="meta-list__description"
            >
                {{ proposal.description?.starts_at }}
            </p>
            <time>
                <slot name="starts_at">
                    {{ proposal.starts_at | dateFormat($t('date-format.date')) }}
                </slot>
            </time>
        </li>

        <li
            v-if="proposal.tariffs && proposal.tariffs.length"
            class="meta-list__item"
        >
            <h3>
                <svg-icon
                    class="meta-list__icon"
                    icon="tariff"
                />
                {{ $t('proposalView.tariffs-title') }}
            </h3>

            <div
                v-if="proposal.description?.tariff"
                class="meta-list__description"
            >
                {{ proposal.description?.tariff }}
            </div>

            <tariff-table :tariffs="proposal.tariffs" />

            <div
                v-if="proposal.tariff_note"
                v-html="proposal.tariff_note"
            />
        </li>

        <proposal-legacy-tariff
            v-else-if="proposal.tariff_type"
            class="meta-list__item"
            :proposal="proposal"
        />

        <li
            v-if="proposal.workloads && proposal.workloads.length"
            class="meta-list__item"
        >
            <h3>
                <svg-icon
                    class="meta-list__icon"
                    icon="workload"
                />
                {{ $t('proposalView.workload-title') }}
            </h3>

            <p
                v-if="proposal.description?.workload"
                class="meta-list__description"
            >
                {{ proposal.description?.workload }}
            </p>

            <tariff-table
                :workloads="proposal.workloads"
                :workload-type="proposal.workloads[0].type"
            />

            <div
                v-if="proposal.workload_note"
                v-html="proposal.workload_note"
            />
        </li>

        <li
            v-if="proposal.fixed_fee_amount"
            class="meta-list__item"
        >
            <h3>
                <svg-icon
                    class="meta-list__icon"
                    icon="fixed-fee"
                />
                {{ $t('proposalView.fixed-fee') }}
            </h3>

            <p
                v-if="proposal.description?.fixed_fee"
                class="meta-list__description"
            >
                {{ proposal.description?.fixed_fee }}
            </p>

            <p>
                &euro; {{ proposal.fixed_fee_amount }}
            </p>

            <div
                v-if="proposal.fixed_fee_note"
                v-html="proposal.fixed_fee_note"
            />
        </li>

        <li
            v-if="proposal.availability_amount"
            class="meta-list__item"
        >
            <h3>
                <svg-icon
                    class="meta-list__icon"
                    icon="availability"
                />
                {{ $t('proposalView.availability') }}
            </h3>

            <p
                v-if="proposal.description?.availability"
                class="meta-list__description"
            >
                {{ proposal.description?.availability }}
            </p>

            <p>
                <slot name="availability">
                    <span>{{ $tc(`availabilityWithAmount.${proposal.availability_per_time_unit}`, proposal.availability_amount) }}</span>
                </slot>
            </p>

            <div
                v-if="proposal.availability_note"
                class="meta-list__description"
                v-html="proposal.availability_note"
            />
        </li>

        <template v-if="proposal.open_questions_answers">
            <li
                v-for="(question) in proposal.open_questions_answers"
                :key="question.id || question.label"
                class="meta-list__item"
            >
                <h3>
                    <svg-icon
                        class="meta-list__icon"
                        icon="quiz"
                    />
                    {{ getQuestionLabel(question) }}
                </h3>
                <div v-html="question.value" />
            </li>
        </template>

        <li
            v-if="proposal.media && proposal.media.length"
            class="meta-list__item"
        >
            <h3>
                <svg-icon
                    class="meta-list__icon"
                    icon="media"
                />
                {{ $t('proposalView.media') }}
            </h3>

            <p
                v-if="proposal.description?.media"
                class="meta-list__description"
            >
                {{ proposal.description?.media }}
            </p>

            <div
                v-for="attachment in proposal.media"
                :key="attachment.id"
            >
                <file-download
                    :hide-icon="true"
                    :attachment="attachment"
                    :href="getAttachmentUrl(attachment.id)"
                />
            </div>
        </li>

        <li
            v-if="proposal.cv?.length"
            class="meta-list__item"
        >
            <h3>
                <svg-icon
                    class="meta-list__icon"
                    icon="resume"
                />
                {{ $t('proposalView.cv') }}
            </h3>

            <p
                v-if="proposal.description?.cv"
                class="meta-list__description"
            >
                {{ proposal.description?.cv }}
            </p>

            <file-download
                :hide-icon="false"
                :attachment="proposal.cv[0]"
                :href="getAttachmentUrl(proposal.cv[0].id)"
            />
        </li>

        <li
            v-if="proposal.remarks"
            class="meta-list__item"
        >
            <h3>
                <svg-icon
                    class="meta-list__icon"
                    icon="note"
                />
                {{ $t('proposalView.remarks') }}
            </h3>

            <p
                v-if="proposal.description?.remarks"
                class="meta-list__description"
            >
                {{ proposal.description?.remarks }}
            </p>

            <div v-html="proposal.remarks" />
        </li>
    </ul>
</template>

<script>
import TariffTable from '~/patterns/molecules/tariff-table/tariff-table.vue';
import ProposalLegacyTariff from '~/patterns/molecules/proposal-legacy-tariff/proposal-legacy-tariff.vue';
import FileDownload from '~/patterns/atoms/file-download/file-download.vue';
import ProposalApi from '~/api/proposals';

export default {
    components: {
        ProposalLegacyTariff,
        TariffTable,
        FileDownload
    },

    props: {
        proposal: {
            type: Object,
            default: () => { return {}; }
        }
    },

    computed: {
        isCommunityManagerProposal() {
            return this.proposal?.via;
        },

        lawyerParticipant() {
            if (!this.isCommunityManagerProposal) {
                return this.proposal.user;
            }

            return this.proposal?.message?.threads?.[0]?.lawyerParticipants?.[0];
        }
    },

    methods: {
        getAttachmentUrl(mediaId) {
            return `${ProposalApi.getMedia(this.proposal.id, mediaId)}`;
        },
        getQuestionLabel(question){
            const openQuestion = this.proposal?.assignment?.proposal_form_config?.open_questions?.find(openAssignmentQuestion => openAssignmentQuestion?.id === question?.id);
            return openQuestion?.label || question.label;
        }
    }
};
</script>

<style lang="less">
.proposal-meta.meta-list {
    .meta-list__item h3, .meta-list__item p, .meta-list__item time {
        .font-text();
        margin-bottom: .25rem;
    }

    .meta-list__item + .meta-list__item {
        margin-top: 1.5rem;
    }
}

.proposal-meta .meta-list__description {
    font-weight: 400;
    margin-bottom: .25rem;
}

.proposal-meta__tag {
    margin-bottom: .75rem;
}

.proposal-meta__status {
    justify-content: flex-end;
    display: flex;
}
</style>
